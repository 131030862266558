<template>
  <div
    id="page-banner2"
    :class="
      !sol && !seg
        ? 'page-head head-segmentos ' + imgBack
        : 'page-head head-todas-solucoes'
    "
  >
    <div class="container">
      <nav class="breadcrumb">
        <ul class="breadcrumb" itemscope>
          <li itemprop="itemListElement" itemscope>
            <i :class="icon1"></i>
            <span itemprop="name" class="breadcrumb-span">{{ itemProps }}</span>
          </li>
        </ul>
      </nav>

      <h1 id="title" class="title-h1" style="text-align: center">
        <i :class="icon"></i> {{ title }}
      </h1>
    </div>
  </div>
</template>
<script>
export default {
  props: ["imgBack", "itemProps", "icon1", "icon", "title"],
  data() {
    return {
      seg: "",
      sol: ""
    };
  },
  created() {
    this.seg = this.$route.name === "Segmentos";
    this.sol = this.$route.name === "Solucoes";
  },
  mounted() {
    if (this.$route.name === "Gold" || this.$route.name === "Garnet") {
      document.getElementById("title").classList.add("notranslate");
    }
  },
  components: {}
};
</script>
<style scoped>
@import url("./index.css");
</style>
