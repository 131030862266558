<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-mvp" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <p>
                  Para criar um MVP com êxito, é preciso seguir alguns passos
                  importantes:
                </p>
                <ul class="paragraph-ul">
                  <li>
                    Avaliar qual é o problema que seu produto resolve e para
                    quem? Por que preciso desse produto e como ele pode ajudar?
                    Responder a essas perguntas ajudará a entender o objetivo
                    principal do seu produto e a encontrar a melhor solução para
                    as necessidades reais dos seus futuros clientes, seu
                    público-alvo.
                  </li>
                  <li>
                    Analisar a concorrência e verificar a existência de produtos
                    similares e identificar como sua solução se destaca dentre
                    os concorrentes
                  </li>
                  <li>
                    Definição dos processos e funcionalidades essenciais, onde
                    todos os excessos devem ser enxugados para chegar à essência
                    mais elementar do produto, que permita a experiência e
                    feedback dos usuários.
                  </li>
                  <li class="duvida-li">
                    <span @click="$router.push({ name: 'Duvida04' })"
                      >Teste seu produto</span
                    >
                    para verificar o desempenho, detectar possíveis bugs e
                    corrigi-los. Essa etapa de testes é conduzida pelos
                    analistas e diretores de qualidade, cujo trabalho é melhorar
                    o produto enquanto ele ainda não foi lançado, através de
                    testes alfa ou beta.
                  </li>
                </ul>
                <div class="paragraph">
                  <h2 class="title-outline" style="font-size: 24px">
                    Benefícios de se fazer um MVP:
                  </h2>
                  <ul class="paragraph-ul">
                    <li>Testar uma hipótese com poucos recursos;</li>
                    <li>Evitar falhas maiores e despesas desnecessárias;</li>
                    <li>Verificar tendências do mercado;</li>
                    <li>
                      Cooperação e trabalho em conjunto com potenciais usuários
                      na elaboração da versão final do produto;
                    </li>
                    <li>
                      Menor tempo entre o lançamento do produto e a aquisição
                      dos primeiros usuários;
                    </li>
                    <li>Expansão da sua base de usuários;</li>
                    <li>Possibilidade de atrair investidores.</li>
                  </ul>
                  <h2 class="title-outline" style="font-size: 24px">
                    Diferença entre Soft MVP (Baixa fidelidade) x HARD MVP (Alta
                    fidelidade)
                  </h2>
                  <p>
                    Existem muitos tipos de MVP, porém aqui vamos entender o
                    conceito de duas categorias de MVP: Baixa fidelidade e Alta
                    fidelidade, também chamados de Lo-Fi (do inglês Low
                    Fidelity) e Hi-Fi (do inglês High Fidelity) respectivamente.
                    Lembrando sempre das características principais de um MVP:
                  </p>
                  <ul class="paragraph-ul">
                    <li>
                      Ter valor suficiente para que as pessoas comecem a
                      utilizá-lo
                    </li>
                    <li>
                      Demonstrar benefícios suficientes para reter usuários
                      iniciais
                    </li>
                    <li>
                      Fornecer um ciclo de feedback para orientar o
                      desenvolvimento futuro
                    </li>
                  </ul>
                  <p>
                    Em um MVP de baixa fidelidade existe pouca ou nenhuma
                    tecnologia utilizada. Ele é utilizado para mapear, avaliar e
                    compreender os problemas do cliente – verificar o quão
                    valiosa a solução pode ser para um problema específico,
                    investigar se vale a pena ou não resolvê-lo e explorar qual
                    tipo de solução seria mais eficaz para as dores que o
                    cliente tem.
                  </p>
                  <p>
                    Já um MVP de alta fidelidade é muito mais fiel ao seu
                    produto ou serviço final, mas também é mais difícil de
                    fazer. Um MVP de alta fidelidade envolve mais tecnologia e
                    engenhosidade para criar algo parecido com a solução com
                    poucos recursos.
                  </p>
                  <p>
                    Serve, principalmente, para descobrir quanto os clientes
                    estão dispostos a pagar pela solução, para encontrar os
                    primeiros clientes, para definir e otimizar sua estratégia
                    de Marketing e para identificar as melhores estratégias de
                    crescimento potencial.
                  </p>
                </div>
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->
            <div class="col-md-6">
              <div class="container">
                <div class="paragraph">
                  <h3 style="font-size: 20px">Tipos de Soft MVP:</h3>
                  <ul class="paragraph-ul">
                    <li>
                      <b>Cortina de fumaça:</b> Aqui o produto ainda não é
                      disponível, então são realizadas divulgações a fim de
                      capturar o interesse, e avaliar se o produto ou serviço é
                      atrativo. Aqui a ideia pode ser validada ensinando o
                      cliente através de um vídeo, blog, por meio de um canal
                      para divulgação (seja uma landing page ou em mídias
                      sociais), ou através de anúncios para capturar informações
                      e compreender o que o cliente necessita.
                    </li>
                    <li>
                      <b>Concierge:</b> Frequentemente confundido com o Mágico
                      de Oz, nesse tipo de MVP o cliente sabe que o serviço é
                      manual. Você trabalha com cada cliente individualmente
                      para resolver seu problema. Enquanto o MVP Mágico de Oz é
                      mais recomendado quando você tem entendimento claro da
                      solução e está testando o mercado, o Concierge deve ser
                      usado quando você não tem clareza da solução.
                    </li>
                  </ul>
                  <h3 style="font-size: 20px">Tipos de Hard MVP:</h3>
                  <ul class="paragraph-ul">
                    <li>
                      <b>Fragmentado:</b> É praticamente um meio-termo entre o
                      Mágico de Oz e o Concierge. Com o MVP Fragmentado, você
                      usa ferramentas e outros serviços existentes para resolver
                      os problemas do cliente. São usadas várias tecnologias
                      junto com a ação humana.
                    </li>
                    <li>
                      <b>Mágico de Oz:</b> Nesse caso, a interface do aplicativo
                      ou site deve estar pronta e funcionando nas mãos dos
                      usuários para os testes. Entretanto, como as automações
                      ainda não estão prontas, as tarefas são feitas manualmente
                      para obter insights, colher respostas e entender as
                      necessidades dos clientes. O MVP Mágico de Oz tem esse
                      nome porque cria a ilusão de tecnologia. Um exemplo que
                      ilustra isso muito bem é o do Easy Taxi. O usuário entrava
                      numa landing page e preenchia um formulário para pedir um
                      táxi. Os próprios fundadores ligavam para o ponto de táxi
                      e o mandavam até o cliente. O cliente acreditava que era
                      tudo feito pela tecnologia.
                    </li>
                    <li>
                      <b>Crowdfunding:</b> O Crowdfunding é como uma pré-venda
                      da sua solução. Com isso, você vê se os clientes realmente
                      pagariam pela sua solução e arrecadam os recursos antes de
                      começar a produzir ou desenvolver. Além disso, os clientes
                      podem divulgar o produto para bater a meta de arrecadação.
                    </li>
                    <li>
                      <b>Beta (ou protótipo):</b> Exemplar funcional, uma
                      “versão beta”, que requer um maior investimento, e que se
                      aproxima da versão final do produto, sendo oferecido para
                      um grupo restrito.
                    </li>
                  </ul>
                  <!--  -->
                  <p>
                    Agora que sabemos o que é um MVP e que tipo de MVP existe,
                    você está pronto para colocar suas ideias em ação e
                    validá-las com clientes reais.
                  </p>
                  <p>
                    Após as fases descritas e a ideia validada, o produto é
                    lançado e passa a ser disponibilizado para o público, sendo
                    necessário compreender a receptividade do seu público-alvo,
                    a fim de trazer melhorias na experiência dos usuários.
                  </p>
                  <p>
                    Para entender como fazer isso, entre em contato conosco por
                    <a href="mailto:comercial@shinier.com.br"
                      >comercial@shinier.com.br</a
                    >
                    , ou por WhatsApp
                    <a @click="send()">+5516981354817 (Marcius)</a>
                  </p>
                </div>
              </div>
            </div>
            <!-- fim col-md-6 direita -->
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import send from "@/mixins/sendMessage";
import Duvidas from "@/components/Duvidas";

export default {
  name: "Duvida08",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR",
    },
    title: "Como criar um MVP passo a passo",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary",
      },
      {
        name: "twitter:title",
        content: "Como criar um MVP passo a passo | Shinier",
      },

      {
        name: "description",
        content:
          "Um MVP é uma versão mínima do produto, apenas com as funcionalidades necessárias para que ele cumpra a função para a qual foi planejado. É util para investir o minimo necessário para se avaliar o modelo do negócio, antes de se investir em automatizações.",
      },

      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Como criar um MVP passo a passo | Shinier",
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/como-criar-um-mvp-passo-a-passo",
      },
      {
        property: "og:description",
        content:
          "Um MVP é uma versão mínima do produto, apenas com as funcionalidades necessárias para que ele cumpra a função para a qual foi planejado. É util para investir o minimo necessário para se avaliar o modelo do negócio, antes de se investir em automatizações.",
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/smart-home-software.05b2f56d.png",
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/smart-home-software.05b2f56d.png",
      },
      {
        name: "twitter:description",
        content:
          "Um MVP é uma versão mínima do produto, apenas com as funcionalidades necessárias para que ele cumpra a função para a qual foi planejado. É util para investir o minimo necessário para se avaliar o modelo do negócio, antes de se investir em automatizações.",
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" },
    ],
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback,
  },
  mixins: [showQuestions, scrollToTop, send],
};
</script>
<style>
@import url("./duvidas.css");
</style>
