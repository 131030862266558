<template>
  <div>
    <div class="image-feedback-card"></div>
    <h3 class="feedback-title">
      Deixe seu contato para assinar nossa newsletter, e receba mais conteúdos
      exclusivos!
    </h3>
    <div class="form-feedback">
      <fieldset class="fieldset" v-if="$store.state.isLike === 'like'">
        <legend class="fieldset-legend text-center">
          E-mail ou WhatsApp (opcional)
        </legend>

        <input
          class="feedback-input"
          name="email"
          type="text"
          v-model="contact"
        />
      </fieldset>
      <fieldset class="fieldset" v-if="$store.state.isLike === 'unlike'">
        <legend class="fieldset-legend text-center">
          Por que o conteúdo não é útil?
        </legend>

        <select
          class="feedback-input"
          v-model="selected"
          @change="getSelected(selected)"
        >
          <option
            :id="item.id"
            class="feedback-input"
            v-for="item in options"
            :value="item"
            :key="item.text"
          >
            {{ item.text }}
          </option>
        </select>
      </fieldset>
      <button class="btn btn-orange-solid btn-feedback" @click="send()">
        Enviar
      </button>
    </div>
  </div>
</template>

<script>
import { toast } from "@/utils";
import api from "../../services";
export default {
  data() {
    return {
      contact: "",
      options: [
        { id: "discordo", text: "discordo do que foi abordado;" },
        {
          id: "concordo",
          text: "concordo parcialmente com o artigo, mas tenho ressalvas;"
        },
        { id: "outro", text: "pensei que fosse falar sobre outro assunto;" },
        { id: "superficial", text: "o assunto foi abordado superficialmente;" }
      ],
      selected: {},
      isLike: this.$store.state.isLike
    };
  },
  methods: {
    getSelected(select) {
      this.selected = select;
    },
    send() {
      this.saveContact();
      toast(
        this,
        "is-success",
        this.isLike === "unlike"
          ? "Obrigado por responder!"
          : "Obrigado por responder! Em breve você receberá nossas novidades!"
      );
      this.closePopup();
    },
    saveContact() {
      var msg = Object.assign({
        route: this.$route.fullPath,
        email: this.contact || "N/A",
        feedback: this.isLike,
        isNotUtilFeedback: this.isLike === "unlike" ? this.selected.text : "N/A"
      });
      api
        .post("/feedback/create", msg)
        .then(response => console.log(response.data));
    },
    closePopup() {
      var fade = document.getElementById("card-feedback");
      fade.style.display = "none";
    }
  }
};
</script>

<style>
.btn-feedback {
  border-radius: 10px !important;
  border: transparent;
}
.feedback-title {
  font-size: 2em;
  text-align: center;
  font-weight: 600;
}
.form-feedback {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 5%;
  width: max-content;
}
.feedback-input {
  width: max-content;
  height: 5vh;
  border-radius: 8px;
  color: #515151;
  font-size: 18px;
  text-align: center;
}

.feedback-input[type="text"] {
  width: 35vw;
}

/* 
.feedback-input select {
  height: 38px;
  line-height: 38px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  color: #515151;
  font-size: 12px;
}
 */
div.image-feedback-card {
  background: url(../../assets/images/imp-economico.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  border-radius: 10px;
  height: 15vh;
}

@media screen and (max-width: 1024px) {
  div.image-feedback-card,
  .form-feedback {
    width: auto;
  }
  .feedback-title {
    padding: 1%;
  }
  .feedback-input,
  .feedback-input[type="text"] {
    width: 90vw;
  }
}
</style>
