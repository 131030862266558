<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-poc" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <p>
                  Além disso, a prova de conceito permite a solicitação de
                  feedbacks internos e externos. Assim, os testes são realizados
                  sem muita exposição e permite-se a correção de erros e
                  implementação de melhorias.
                </p>
                <p>
                  No futuro, a fim de realizar um pedido de patente de produtos,
                  você pode utilizar a POC como prova de propriedade
                  intelectual. Dentre seus principais benefícios estão:
                </p>

                <ul class="paragraph-ul">
                  <li>
                    A redução de custos e riscos, uma vez que a ideia é testada
                    antes mesmo de se investir recursos nela
                  </li>
                  <li>
                    Uma prova objetiva da viabilidade do projeto através de
                    resultados tangíveis, já que a prova de conceito também é
                    uma evidência documentada daquilo que se quer provar sobre o
                    produto final.
                  </li>
                  <li>
                    Aumento da satisfação do cliente, pois nesse processo não é
                    apenas testada a viabilidade da ideia, mas também a técnica
                    e o funcionamento do produto. Dessa forma, é possível
                    identificar falhas ou possíveis ajustes que podem ser
                    realizados.
                  </li>
                  <li>
                    Uma vez que a prova de conceito gera dados documentados, ela
                    também melhora o embasamento para tomada de novas decisões
                  </li>
                </ul>
                <p>
                  A prova de conceito é um documento essencial para testar o
                  produto de forma eficiente e garantir que o processo vai
                  correr de forma coesa.
                </p>
                <p>
                  É importante distinguir a prova de conceito utilizada na área
                  de TI daquela feita em empresas e startups. A última é útil
                  para entender a viabilidade financeira de qualquer projeto ou
                  produto, analisando a possível resposta do mercado e os
                  custos.
                </p>
                <p>
                  No que diz respeito a softwares, trata-se mais da viabilidade
                  técnica e menos do potencial financeiro. No entanto, sabemos
                  que o desenvolvimento de softwares e seu sucesso comercial
                  estão atrelados e por isso a POC desses produtos também faz
                  parte da garantia mercadológica.
                </p>
                <h2 class="title-outline" style="font-size: 24px">
                  Como funciona uma prova de conceito
                </h2>
                <p>
                  Para a realização de uma prova de conceito, pode-se contar com
                  três passos essenciais.
                </p>
                <h3 style="font-size: 20px">1) Requisitos selecionados</h3>
                <p>
                  Antes da elaboração da POC, criamos uma RFP, que é uma lista
                  de requisitos enviada aos principais fornecedores. Os
                  fornecedores, por sua vez, dão uma resposta à empresa a fim de
                  saber se suas soluções atendem ou não aos requisitos. Na RFP
                  foram levantados inúmeros requisitos, porém na POC devemos
                  selecionar apenas os principais.
                </p>
                <p>
                  Vamos imaginar que na RFP foram definidos mil requisitos, por
                  exemplo. Escolhe-se para a prova de conceito apenas entre dez
                  a quinze entre eles. É importante analisar quais os requisitos
                  mais essenciais e determinantes para o projeto.
                </p>
                <!--  -->
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->

            <div class="col-md-6">
              <div class="container">
                <div class="paragraph">
                  <h3 style="font-size: 20px">2) Processo ponta a ponta</h3>
                  <p>
                    Após os requisitos levantados, entra o processo ponta a
                    ponta. Nessa fase, o fornecedor irá montar uma apresentação
                    que responda se a solução cumpre os requisitos que foram
                    definidos. O processo ponta a ponta pode ser:
                  </p>
                  <ul class="paragraph-ul">
                    <li>Da compra à venda;</li>
                    <li>Do estoque à manufatura;</li>
                    <li>Da capacitação do cliente a prestação de serviço.</li>
                  </ul>
                  <h3 style="font-size: 20px">3) Usabilidade</h3>
                  <p>
                    Por fim, o fornecedor irá provar a usabilidade do produto.
                    Essa etapa é onde realmente se tira a prova de conceito. É
                    aqui que a empresa irá testar as funcionalidades do produto
                    do fornecedor e ver se ele cumpre com o que foi proposto.
                  </p>
                  <h2 class="title-outline" style="font-size: 24px">
                    Como elaborar uma POC (prova de conceito)
                  </h2>
                  <p>
                    Para desenvolver um POC de forma eficiente em seu projeto,
                    você precisa analisar algumas questões básicas como:
                  </p>
                  <h3 style="font-size: 20px">Definição de objetivos</h3>
                  <p>
                    Esta etapa é essencial para criar uma prova de conceito. É
                    muito importante que a equipe documente todos os objetivos
                    que a empresa deseja alcançar com um determinado produto.
                    Isso faz a diferença porque ao final você pode verificar se
                    os objetivos que você criou foram devidamente alcançados.
                  </p>
                  <h3 style="font-size: 20px">
                    Escolher conceitos para testar
                  </h3>
                  <p>
                    Para desenvolver uma prova de conceito, você precisa
                    escolher quais ideias testar. Esse também é um passo
                    fundamental para deixar claro para a equipe e fornecedores
                    que o produto deve ser validado. Além disso, essa etapa
                    ajuda diretamente na coleta de requisitos.
                  </p>
                  <h3 style="font-size: 20px">Unir a equipe</h3>
                  <p>
                    O envolvimento do time durante o processo também é muito
                    importante. Dessa forma, é possível ter diversas opiniões e
                    pontos de vista, tornando mais provável que o produto atenda
                    às expectativas de todo o time e dos contratantes, uma vez
                    que eles participaram de todo o processo. Além das perguntas
                    acima, a preparação de um POC também envolve a criação de:
                  </p>
                  <ul class="paragraph-ul">
                    <li>Critérios claramente definidos;</li>
                    <li>
                      Documentação de como a prova de conceito será realizada;
                    </li>
                    <li>
                      Uma proposta de como avançar caso o fornecedor passe na
                      POC.
                    </li>
                  </ul>
                  <p>
                    Uma prova de conceito (POC) é uma etapa fundamental em
                    qualquer implementação de projeto. Por isso, saber como
                    implementá-lo corretamente na sua empresa é muito
                    importante.
                  </p>
                  <p>
                    Para entender como fazer isso, entre em contato conosco por
                    <a href="mailto:comercial@shinier.com.br"
                      >comercial@shinier.com.br</a
                    >
                    , ou por WhatsApp
                    <a @click="send()">+5516981354817 (Marcius)</a>
                  </p>
                </div>
              </div>
            </div>
            <!-- fim col-md-6 direita-->
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import Duvidas from "@/components/Duvidas";
import send from "@/mixins/sendMessage";

export default {
  name: "Duvida06",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR",
    },
    title: "Proof of concept (PoC): O que é e porque é importante?",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary",
      },
      {
        name: "twitter:title",
        content:
          "Proof of concept (PoC): O que é e porque é importante? | Shinier",
      },
      {
        name: "description",
        content:
          "A Prova de Conceito é utilizada para que se verifique se o produto funciona em um nível básico, sem ainda pensar em como ele vai performar de fato no mercado. A ideia é remover erros da arquitetura do software e garantir que ele vá rodar em boa forma, além de compreender o por quê e para que o cliente precisa daquela solução",
      },

      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content:
          "Proof of concept (PoC): O que é e porque é importante? | Shinier",
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/proof-of-concept-poc-o-que-e-e-porque-e-importante",
      },
      {
        property: "og:description",
        content:
          "A Prova de Conceito é utilizada para que se verifique se o produto funciona em um nível básico, sem ainda pensar em como ele vai performar de fato no mercado. A ideia é remover erros da arquitetura do software e garantir que ele vá rodar em boa forma, além de compreender o por quê e para que o cliente precisa daquela solução",
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/prova-conceito.7aa64e0d.png",
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/prova-conceito.7aa64e0d.png",
      },
      {
        name: "twitter:description",
        content:
          "A Prova de Conceito é utilizada para que se verifique se o produto funciona em um nível básico, sem ainda pensar em como ele vai performar de fato no mercado. A ideia é remover erros da arquitetura do software e garantir que ele vá rodar em boa forma, além de compreender o por quê e para que o cliente precisa daquela solução",
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" },
    ],
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback,
  },
  mixins: [showQuestions, scrollToTop, send],
};
</script>
<style>
@import url("./duvidas.css");
</style>
