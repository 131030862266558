<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-api" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <p>
                  Elas funcionam através da comunicação de diversos códigos,
                  definindo comportamentos específicos de determinado objeto em
                  uma interface. A API liga as diversas funções de um site de
                  maneira que possam ser utilizadas em outras aplicações.
                </p>
                <p>
                  Existem diversos tipos de API 's , elas podem ser abertas
                  (onde qualquer usuário pode acessar as informações), ou
                  privadas (onde um grupo restrito de usuários podem acessar e
                  fazer alterações, utilizadas em sistemas empresariais internos
                  por exemplo), entre outros tipos. Através da API 's, é
                  possível estabelecer uma comunicação entre sistemas
                  diferentes, ou até mesmo auxiliar desenvolvedores a criar
                  aplicações que tenham certas funcionalidades sem
                  necessariamente desenvolver algo do zero, ou integrar uma
                  plataforma com outra, mesmo que sejam completamente distintas
                  (como por exemplo, ter uma função para exibir um mapa de
                  restaurantes da sua região através da geolocalização do Google
                  Maps, ou ter algo que permita fazer login em uma plataforma
                  com o Facebook).
                </p>

                <p>
                  As APIs permitem que as organizações determinem quem pode
                  acessar e o que podem acessar, permitindo que compartilhem
                  recursos e informações enquanto mantêm a segurança, o controle
                  e a aplicação da autenticação. Um exemplo é uma API que usa a
                  arquitetura REST. Consiste em um conjunto de políticas que
                  podem ser implementadas conforme necessário. Isso torna a API
                  REST mais rápida, leve e escalável, tornando-a ideal para o
                  desenvolvimento de Internet das Coisas (IoT) e aplicativos
                  móveis.
                </p>
                <p>
                  Para entender como fazer isso, entre em contato conosco por
                  <a href="mailto:comercial@shinier.com.br"
                    >comercial@shinier.com.br</a
                  >
                  , ou por WhatsApp
                  <a @click="send()">+5516981354817 (Marcius)</a>
                </p>
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->
            <div class="col-md-6">
              <div class="container">
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/app-example.png"
                    alt="Uma mão segurando celular, na tela um aplicativo de geolocalização, e ao fundo no painel do carro um mapa"
                  />
                </figure>
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/cloud.png"
                    alt="cloud computing"
                  />
                </figure>
              </div>
            </div>
            <!-- fim col-md-6 direita -->
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>

        <!--  <Footer /> -->
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import Duvidas from "@/components/Duvidas";
import send from "@/mixins/sendMessage";

export default {
  name: "Duvida05",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "O que é e como funciona uma API?",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "O que é e como funciona uma API? | Shinier"
      },
      {
        name: "description",
        content:
          "API é um acrônimo para Application Programming Interface Trata-se de um conjunto de rotinas e padrões que facilitam a comunicação e troca de informações entre sistemas. Em resumo é um “conector” pré-estabelecido para um sistema. Uma API liga as diversas funções de um site de maneira que possam ser utilizadas em outras aplicações."
      },

      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "O que é e como funciona uma API? | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/o-que-e-e-como-funciona-uma-api"
      },
      {
        property: "og:description",
        content:
          "API é um acrônimo para Application Programming Interface Trata-se de um conjunto de rotinas e padrões que facilitam a comunicação e troca de informações entre sistemas. Em resumo é um “conector” pré-estabelecido para um sistema. Uma API liga as diversas funções de um site de maneira que possam ser utilizadas em outras aplicações."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/cloud.ac2615dd.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/cloud.ac2615dd.png"
      },
      {
        name: "twitter:description",
        content:
          "API é um acrônimo para Application Programming Interface Trata-se de um conjunto de rotinas e padrões que facilitam a comunicação e troca de informações entre sistemas. Em resumo é um “conector” pré-estabelecido para um sistema. Uma API liga as diversas funções de um site de maneira que possam ser utilizadas em outras aplicações."
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" }
    ]
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback
  },
  mixins: [showQuestions, scrollToTop, send]
};
</script>
<style>
@import url("./duvidas.css");
</style>
