<template>
  <div>
    <section class="list list-new-destaques">
      <div class="columns is-centered is-vcentered is-mobile">
        <div class="column">
          <button
            @click="share(0)"
            class="btn btn-social social-buttons"
            style="background-color: #1877f2"
          >
            <span class="btn-facebook"></span>
            <span class="social-text">Facebook</span>
          </button>
        </div>
        <div class="column">
          <button
            @click="share(1)"
            class="btn btn-social social-buttons"
            style="background-color: #0a66c2"
          >
            <span class="btn-linkedin"></span>
            <span class="social-text">Linkedin</span>
          </button>
        </div>
        <div class="column">
          <button
            @click="share(2)"
            data-action="share/whatsapp/share"
            class="btn btn-social social-buttons"
            style="background-color: #20b038"
          >
            <span class="btn-whatsapp"></span>
            <span class="social-text">Whatsapp</span>
          </button>
        </div>
        <!-- <div class="column">
            <button
              @click="share(2)"
              class="btn btn-social social-buttons"
              style="
                background: radial-gradient(
                  92.18% 99.11% at 26.56% 107.7%,
                  #ffdd55 0%,
                  #ffdd55 10%,
                  #ff543e 50%,
                  #c837ab 100%
                );
              "
            >
              <span class="btn-instagram"></span>
              <span class="social-text">Instagram</span>
            </button>
          </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      window: window.innerWidth > 920,
      wSize: "width=600,height=960",
      loc: "" /* `https://shinier.com.br${this.$route.path}` */,
      page_title: "",
      // Sharer URLs
      fb: "https://www.facebook.com/sharer/sharer.php?u=", //  Facebook
      li: "https://www.linkedin.com/sharing/share-offsite/?url=", // LinkedIn
      wa: `https://wa.me?text=*${document.title}*
      `, //WhatsApp
      // URLs array
      url: []
    };
  },
  mounted() {
    this.loc = window.location.href;
    this.page_title = document.title;
    this.url.push(this.fb, this.li, this.wa);
  },
  methods: {
    share(i) {
      "use strict";
      window.open(this.url[i] + this.loc, this.page_title, this.wSize);
    }
  }
};
</script>

<style>
.btn-social {
  padding-left: 10% !important;
  padding-right: 10% !important;
  border: none;
  height: max-content !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}
.btn-linkedin {
  content: url(../../assets/icons/social-share-linkedin.svg);
  height: 2.7rem;
  margin: auto;
}
.btn-facebook {
  content: url(../../assets/icons/social-share-facebook.svg);
  height: 2rem;
  margin: auto;
}

.btn-whatsapp {
  content: url(../../assets/icons/social-share-whatsapp.svg);
  height: 2rem;
  margin: auto;
}

.btn-instagram {
  content: url(../../assets/icons/social-share-instagram.svg);
  height: 2rem;
  margin: auto;
}

.social-text {
  color: antiquewhite;
  margin-left: 5%;
}

@media screen and (max-width: 920px) {
  .social-text {
    display: none;
  }
  .btn-social {
    width: 65px;
    height: 40px !important;
  }
}
</style>
