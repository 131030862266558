const showQuestions = {
  data() {
    return {
      infos: this.$store.getters.questions,
      title: "",
      imgCard: "",
      desc: ""
    };
  },
  created() {
    let r = this.$store.getters.questions.map(c => c.route);
    for (let routes = 0; routes < r.length; routes++) {
      if (r[routes] === this.$route.name) {
        this.title = this.infos[routes].title;
        this.imgCard = this.infos[routes].imgCard;
        this.desc = this.infos[routes].desc;
      }
    }
  }
};
export default showQuestions;
