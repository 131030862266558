<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-etapas" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <p>
                  Qualquer projeto de software, após garantida a sua
                  viabilidade, precisa passar por um levantamento e análise de
                  requisitos. Nessa fase são definidas as funcionalidades que
                  deverão existir ou não dentro do projeto, bem como a
                  necessidade real do cliente. Além disso, dentro da análise de
                  requisitos podem ser encontradas possíveis ambiguidades e
                  identificar a melhor solução para o usuário final, pois ao
                  final ficará claro ao cliente o papel que o software irá
                  desempenhar ou não.
                </p>
                <p>
                  Após o levantamento de requisitos, são definidas as
                  ferramentas que serão utilizadas para se chegar ao resultado,
                  e então passar para a fase onde o software será
                  implementado/desenvolvido de acordo com todos os requisitos
                  pré estabelecidos.
                </p>
                <p>
                  Ao se obter uma versão estável, passamos para a
                  <a @click="$router.push({ name: 'Duvida04' })"
                    >fase de testes, onde serão simuladas diversas situações, a
                    fim de encontrar possíveis falhas ou pontos de melhoria.</a
                  >
                  Dentro do desenvolvimento de software existem diversos testes
                  a serem realizados como testes de usabilidade, testes
                  funcionais, de performance, entre outros.
                </p>
                <p>
                  Após os testes, entra a fase de validação, onde o cliente faz
                  a verificação e dá o feedback de se o que foi desenvolvido
                  atende às expectativas e fornece o que é esperado. Por fim, é
                  realizada a implantação, que é a entrega do produto final para
                  o cliente e os demais usuários.
                </p>
                <p>
                  Para entender como fazer isso, entre em contato conosco por
                  <a href="mailto:comercial@shinier.com.br"
                    >comercial@shinier.com.br</a
                  >
                  , ou por WhatsApp
                  <a @click="send()">+5516981354817 (Marcius)</a>
                </p>
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->

            <div class="col-md-6">
              <div class="container">
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/desenvolvimento-background.png"
                    alt="imagem de um notebookj e monitor ao fundo com códigos na tela"
                  />
                </figure>
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/planejamento-software.png"
                    alt="pessoas ao redor de papéis sobre uma mesa representando planejamento"
                  />
                </figure>
              </div>
            </div>
            <!-- fim col-md-6 direita -->
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>

        <!--  <Footer /> -->
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import Duvidas from "@/components/Duvidas";
import send from "@/mixins/sendMessage";

export default {
  name: "Duvida03",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Quais são as etapas do desenvolvimento de um software?",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content:
          "Quais são as etapas do desenvolvimento de um software? | Shinier"
      },
      {
        name: "description",
        content:
          "A organização em etapas bem definidas do processo Desenvolvimento de Software facilita o controle e interação do processo, proporcionando uma maior agilidade, resposta às mudanças ao longo do processo e, principalmente, qualidade do software."
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content:
          "Quais são as etapas do desenvolvimento de um software? | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/quais-sao-as-etapas-desenvolvimento-software"
      },
      {
        property: "og:description",
        content:
          "A organização em etapas bem definidas do processo Desenvolvimento de Software facilita o controle e interação do processo, proporcionando uma maior agilidade, resposta às mudanças ao longo do processo e, principalmente, qualidade do software."
      },
      {
        property: "og:image",
        content:
          "https://shinier.com.br/img/desenvolvimento-background.756cebe9.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content:
          "https://shinier.com.br/img/desenvolvimento-background.756cebe9.png"
      },
      {
        name: "twitter:description",
        content:
          "A organização em etapas bem definidas do processo Desenvolvimento de Software facilita o controle e interação do processo, proporcionando uma maior agilidade, resposta às mudanças ao longo do processo e, principalmente, qualidade do software."
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" }
    ]
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback
  },
  mixins: [showQuestions, scrollToTop, send]
};
</script>
<style>
@import url("./duvidas.css");
</style>
