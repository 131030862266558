<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-testes" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <p>
                  Existem diferentes tipos de testes, a fim de identificar e
                  classificar de maneira devida as falhas e consequentemente
                  otimizar as soluções. Alguns deles:
                </p>

                <ul class="paragraph-ul">
                  <li>
                    Caixa branca - Também chamado de teste estrutural ou caixa
                    de vidro, feitos diretamente no código, tendo como objetivo
                    validar a lógica do software.
                  </li>
                  <li>
                    Caixa preta - Também conhecido como teste funcional, seu
                    objetivo é validar os requisitos funcionais,utilizando da
                    interface do produto.
                  </li>
                  <li>
                    Usabilidade - O teste de usabilidade é uma forma de provar a
                    consistência e a solidez de uma interface ao colocá-la para
                    uso em situações comuns. É um teste que busca entender como
                    o sistema se comporta no dia a dia, e se ele atende aos
                    requisitos estabelecidos.
                  </li>
                  <li>
                    Aceitação - São testes geralmente feitos por clientes beta,
                    após todos os outros testes, a fim de obter um feedback
                    antecipado, coletado para corrigir quaisquer defeitos ou
                    falhas que venham aparecer durante o uso.
                  </li>
                </ul>

                <p>
                  É importante que os testes de software sejam um processo
                  automatizado, por se tratar de rotinas repetitivas e que
                  demandam atenção. Além disso, ao automatizar os testes, a
                  execução que antes exigia a entrada repetida de dados todas as
                  vezes em que fosse executado se torna mais simples, evitando a
                  entrada incorreta de dados, o desenvolvedor não precisará se
                  preocupar em repetir o mesmo processo, já que ele é feito de
                  forma automática, economizando tempo. e obtendo feedbacks mais
                  rápidos e eficientes.
                </p>
                <p>
                  Para entender como fazer isso, entre em contato conosco por
                  <a href="mailto:comercial@shinier.com.br"
                    >comercial@shinier.com.br</a
                  >
                  , ou por WhatsApp
                  <a @click="send()">+5516981354817 (Marcius)</a>
                </p>
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->
            <div class="col-md-6">
              <div class="container">
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/teste-software.png"
                    alt="uma mão segurando um celular contendo códigos na tela, ao lado direito um notebook e a frente um teclado"
                  />
                </figure>
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/code.png"
                    alt="Código"
                  />
                </figure>
              </div>
            </div>
            <!-- fim col-md-6 direita -->
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>

        <!--  <Footer /> -->
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import Duvidas from "@/components/Duvidas";
import send from "@/mixins/sendMessage";

export default {
  name: "Duvida04",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Quais os tipos de testes de software e por que automatizá-los?",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content:
          "Quais os tipos de testes de software e por que automatizá-los? | Shinier"
      },
      {
        name: "description",
        content:
          "Teste de software é um processo no desenvolvimento do programa, podendo ser feito pelos próprios desenvolvedores, e profissionais especializados, ou pelos clientes beta. O procedimento tem como objetivo antecipar, corrigir falhas e bugs que apareceriam para o usuário final."
      },

      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content:
          "Quais os tipos de testes de software e por que automatizá-los? | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/tipos-de-teste-de-software-e-por-que-automatiza-los"
      },
      {
        property: "og:description",
        content:
          "Teste de software é um processo no desenvolvimento do programa, podendo ser feito pelos próprios desenvolvedores, e profissionais especializados, ou pelos clientes beta. O procedimento tem como objetivo antecipar, corrigir falhas e bugs que apareceriam para o usuário final."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/teste-software.9f9e9889.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/teste-software.9f9e9889.png"
      },
      {
        name: "twitter:description",
        content:
          "Teste de software é um processo no desenvolvimento do programa, podendo ser feito pelos próprios desenvolvedores, e profissionais especializados, ou pelos clientes beta. O procedimento tem como objetivo antecipar, corrigir falhas e bugs que apareceriam para o usuário final."
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" }
    ]
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback
  },
  mixins: [showQuestions, scrollToTop, send]
};
</script>
<style>
@import url("./duvidas.css");
</style>
