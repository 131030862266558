<template>
  <div>
    <!-- abre seção dúvidas -->
    <section class="list list-new-destaques solucoes">
      <div class="container" style="width: 100%">
        <h2 class="title1">
          <span>PRINCIPAIS DÚVIDAS</span>
        </h2>
        <div class="container">
          <p class="text-center">
            Estamos dispostos a responder todas as suas dúvidas. Abaixo você irá
            encontrar as principais perguntas que são levantadas por nossos
            clientes no momento de BRIEFING. Solicite um orçamento, ou
            consultoria, ou tire suas dúvidas com um consultor clicando no botão
            abaixo.
          </p>
        </div>

        <button
          @click="send()"
          class="btn-news-geral btn btn-orange-solid"
          style="border: none"
        >
          <span><em>FALAR COM CONSULTOR</em></span>
        </button>
      </div>

      <!-- abre seção dúvidas (cards) -->
      <section>
        <div
          class="slick-destaques slick-initialized slick-slider"
          style="padding: 2%"
        >
          <div class="columns is-multiline">
            <div
              class="column is-full-tablet is-full-desktop is-half-widescreen"
              v-for="card in getCards"
              :key="card.route"
            >
              <CardDuvida
                :imgCard="card.imgCard"
                :title="card.title"
                :desc="card.desc"
                :route="card.route"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- fecha seção dúvidas (cards) -->
    </section>
    <!-- fecha seção dúvidas -->
  </div>
</template>
<script>
import CardDuvida from "@/components/Cards/CardDuvida";
export default {
  computed: {
    getCards() {
      return this.$store.getters.questions;
    }
  },
  components: {
    CardDuvida
  },
  methods: {
    send() {
      window.open(
        "https://api.whatsapp.com/send?phone=5516981354817&text=Ol%C3%A1%20Marcius%20meu%20nome%20%C3%A9"
      );
    }
  }
};
</script>
