<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-protecao" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <p>
                  Com a evolução da indústria, os softwares passaram a ser
                  comercializados, sendo então necessário uma regulamentação
                  para proteger sua propriedade intelectual , processo tal que
                  ocorre juntamente com o INPI e é semelhante ao registro de uma
                  obra literária.
                </p>
                <p>
                  O registro e a proteção são importantes porque evitam a
                  duplicação de código, o que dificulta a criação de software
                  semelhante. Além disso, o registro facilita a comprovação da
                  propriedade, evitando qualquer dúvida sobre possíveis
                  disputas. Portanto, embora existam várias formas de criar um
                  mesmo software, a proteção prevista em lei deve fornecer o
                  nível mínimo de segurança para um empreendedor que tenha
                  apenas seu software como produto.
                </p>
                <p>
                  No entanto, o registo não garante exclusividade, pois algum
                  concorrente pode criar uma aplicação semelhante e que entregue
                  as mesmas funcionalidades, porém com outra codificação. Sendo
                  assim, o foco deve ser a proteção do seu negócio,e é aqui que
                  as 5 forças de Porter se fazem necessárias, a fim de garantir
                  um bom mercado, e não ser incomodado por concorrentes que
                  ofereçam o mesmo tipo de software.
                </p>

                <p>
                  A teoria das 5 forças identifica os fatores que afetam as
                  atividades do negócio, sendo fundamental para manter uma
                  companhia ou um software seguro no mercado, e em condições de
                  crescimento, identificar e entender os diferentes aspectos de
                  cada força e o quanto eles afetam as suas atividades. As 5
                  forças são:
                </p>
                <ul class="paragraph-ul">
                  <li>rivalidade entre concorrentes</li>
                  <li>ameaça de substitutos</li>
                  <li>ameaça de novos entrantes</li>
                  <li>poder de barganha com os fornecedores</li>
                  <li>poder de barganha com os clientes</li>
                </ul>
                <p>
                  Compreender estes fatores trará a possibilidade de evitar
                  ameaças e aproveitar as oportunidades, agregando valor ao seu
                  negócio.
                </p>
                <p>
                  Para entender como fazer isso, entre em contato conosco por
                  <a href="mailto:comercial@shinier.com.br"
                    >comercial@shinier.com.br</a
                  >
                  , ou por WhatsApp
                  <a @click="send()">+5516981354817 (Marcius)</a>
                </p>
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->

            <div class="col-md-6">
              <div class="container">
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/protecao.png"
                    alt="proteção de software"
                  />
                </figure>
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/copyright.png"
                    alt="copyright"
                  />
                </figure>
              </div>
            </div>
            <!-- fim col-md-6 direita -->
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import send from "@/mixins/sendMessage";
import Duvidas from "@/components/Duvidas";

export default {
  name: "Duvida07",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Como proteger meu software? - Patente e propriedade intelectual",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content:
          "Como proteger meu software? - Patente e propriedade intelectual | Shinier"
      },

      {
        name: "description",
        content:
          "Garantir a patente e propriedade intelectual de um software é algo complexo, apesar de existir o processo no INPI, pequenas alterações irão quebrar a patente. Por isso, o processo de proteção têm foco em garantir a autenticidade do design da interface e marca. Para proteger seu negócio estude as 5 forças de Michael Porter."
      },

      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content:
          "Como proteger meu software? - Patente e propriedade intelectual | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/como-proteger-meu-software-patente-e-propriedade-intelectual"
      },
      {
        property: "og:description",
        content:
          "Garantir a patente e propriedade intelectual de um software é algo complexo, apesar de existir o processo no INPI, pequenas alterações irão quebrar a patente. Por isso, o processo de proteção têm foco em garantir a autenticidade do design da interface e marca. Para proteger seu negócio estude as 5 forças de Michael Porter."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/protecao.d87a7082.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/protecao.d87a7082.png"
      },
      {
        name: "twitter:description",
        content:
          "Garantir a patente e propriedade intelectual de um software é algo complexo, apesar de existir o processo no INPI, pequenas alterações irão quebrar a patente. Por isso, o processo de proteção têm foco em garantir a autenticidade do design da interface e marca. Para proteger seu negócio estude as 5 forças de Michael Porter."
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" }
    ]
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback
  },
  mixins: [showQuestions, scrollToTop, send]
};
</script>
<style>
@import url("./duvidas.css");
</style>
