<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-mockup" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <br />
                <h3 style="font-size: 20px">
                  O papel do mockup no Design de Produtos
                </h3>
                <br />
                <p>
                  Os mockups também são utilizados para além do design de sites
                  e aplicativos, mas também no design de produtos em geral (como
                  camisetas, canecas, etc), e são importantes pois facilitam a
                  visualização do projeto final.
                </p>
                <br />
                <h3 style="font-size: 20px">
                  Exemplo prático do uso de um mockup
                </h3>
                <br />
                <p>
                  Considere o cenário em que um designer está trabalhando na
                  criação de uma interface para uma solução de software. O
                  cliente está ansioso para visualizar como a interface se
                  comportará quando a solução estiver totalmente desenvolvida e
                  pronta para uso. No entanto, em vez de esperar pela conclusão
                  do desenvolvimento, o designer pode empregar uma valiosa
                  ferramenta chamada mockup para proporcionar uma representação
                  visual da interface diretamente no computador. Em termos
                  simples, em vez de apresentar apenas capturas de tela
                  estáticas da interface do software em desenvolvimento, o
                  designer pode oferecer algo que se assemelhe a uma simulação
                  interativa da interface finalizada. Isso permite que o cliente
                  experimente a interface como se já estivesse em funcionamento,
                  interagindo com os elementos, botões e fluxos de forma
                  virtual. Essa abordagem torna o processo de revisão mais
                  envolvente e eficiente, possibilitando ao cliente tomar
                  decisões informadas e sugerir ajustes antes da implementação
                  final da solução de software.
                </p>
                <p>
                  Então os mockups também permitem que sejam feitas alterações
                  ou mudanças caso seja necessário, além de oferecer ao cliente
                  uma apresentação mais próxima do resultado final, diferente de
                  um wireframe, que é um protótipo de fidelidade mais baixa, que
                  mostra os princípios mais básicos, funcionando como um
                  esqueleto. Os mockups também servem como guia para quem está
                  desenvolvendo a interface, além de agregar valor ao projeto.
                </p>
              </div>
              <div class="paragraph">
                <h3 style="font-size: 20px">
                  Vantagens de se fazer um mockup
                </h3>
                <br />
                <p>
                  Um mockup ajuda a tomar decisões finais sobre os esquemas de
                  cores, estilo visual e tipografia de um trazendo a
                  possibilidade de avaliar o aspecto visual do produto e assim
                  escolher o que se encaixa melhor, além de obter feedback de
                  seus usuários em potencial e ainda ter a possibilidade de
                  fazer as alterações necessárias imediatamente– economizando
                  tempo, dinheiro e recursos.
                </p>
                <p>
                  Para entender como fazer isso, entre em contato conosco por
                  <a href="mailto:comercial@shinier.com.br"
                    >comercial@shinier.com.br</a
                  >
                  , ou por WhatsApp
                  <a @click="send()">+5516981354817 (Marcius)</a>
                </p>
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->

            <div class="col-md-6">
              <div class="box-mockup">
                <div class="cta-mockup">
                   <strong>QUER EXPERIMENTAR A INTERFACE INTERATIVA EM AÇÃO?</strong>
                   <div class="paragraph">
                    <p></p>
                    <p>Disponibilizamos um mockup no Figma 
                      para que possa explorar a solução do software em tempo real</p>
                    </div>
                    <a class="btn btn-orange-solid" href="https://www.figma.com/proto/RDGSQlegLmsHgFz1S78Mjz/Small?node-id=4%3A224&starting-point-node-id=4%3A224">Experimente agora</a> 
                </div>
                <div class="card px-2 py-2">
                </div>
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/wireframe.png"
                    alt="Exemplo de wireframe"
                  />
                  <figcaption
                    style="font-size: 12px; text-align: center; margin: auto"
                  >
                    Exemplo de wireframe
                  </figcaption>
                </figure>
              </div>
            </div>
            <!-- fim col-md-6 direita -->
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import Duvidas from "@/components/Duvidas";
import send from "@/mixins/sendMessage";

export default {
  name: "Duvida02",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "O que é e para que serve um Mockup?",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "O que é e para que serve um Mockup? | Shinier"
      },
      {
        name: "description",
        content:
          "Um mockup é um design estático de uma página da Web ou aplicativo que apresenta muitos de seus elementos finais de design, mas não é funcional. Uma maquete não tão polida quanto uma página ativa,  e normalmente inclui alguns dados de espaço reservado."
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "O que é e para que serve um Mockup? | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/o-que-e-um-mockup-e-para-que-serve"
      },
      {
        property: "og:description",
        content:
          "Um mockup é um design estático de uma página da Web ou aplicativo que apresenta muitos de seus elementos finais de design, mas não é funcional. Uma maquete não tão polida quanto uma página ativa,  e normalmente inclui alguns dados de espaço reservado."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/mockup.bcc0b288.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/mockup.bcc0b288.png"
      },
      {
        name: "twitter:description",
        content:
          "Um mockup é um design estático de uma página da Web ou aplicativo que apresenta muitos de seus elementos finais de design, mas não é funcional. Uma maquete não tão polida quanto uma página ativa,  e normalmente inclui alguns dados de espaço reservado."
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" }
    ]
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback
  },
  mixins: [showQuestions, scrollToTop, send]
};
</script>
<style>
@import url("./duvidas.css");
</style>
