<template>
  <div>
    <div class="feedback-container">
      <h2 style="color: #424a60">Este conteúdo foi útil?</h2>
      <div class="feedback-wrapper">
        <div v-if="liked === false">
          <img
            id="like"
            src="../../assets/icons/like.svg"
            alt="like"
            @click="getFeedback('like')"
          />
          <img
            id="unlike"
            src="../../assets/icons/unlike.svg"
            alt="unlike"
            @click="getFeedback('unlike')"
          />
        </div>
        <div v-if="liked === true">
          <h2 style="color: #424a60">Obrigado por responder!</h2>
        </div>
      </div>
      <h2 style="color: #424a60">Compartilhe:</h2>
    </div>
    <SocialShare />

    <div>
      <div class="columns" id="card-feedback">
        <div class="column columns is-mobile is-centered">
          <button
            id="contact-modal-close"
            class="modal-close"
            @click="closePopup"
          >
            <span class="modal-close"><i class="icon-close"></i></span>
          </button>
          <div id="modal" class="card">
            <FormFeedback />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialShare from "../SocialShare/index.vue";
import FormFeedback from "./FormFeedback.vue";
export default {
  components: { SocialShare, FormFeedback },
  data() {
    return {
      liked: false
    };
  },
  methods: {
    getFeedback(feedback) {
      this.liked = true;
      this.$store.dispatch("get_feedback", feedback);
      window.setTimeout(() => {
        this.openModal();
      }, 1000);
    },

    openModal() {
      let fade = document.getElementById("card-feedback");
      fade.style.display = "block";
    },
    closePopup() {
      var fade = document.getElementById("card-feedback");
      fade.style.display = "none";
    }
  },

  destroyed() {
    this.liked = false;
  },
  updated() {
    this.liked = false;
  }
};
</script>

<style>
.feedback-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.feedback-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.feedback-wrapper img {
  width: 220px;
  padding: 9%;
}

div#modal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50vw;
  height: max-content;
  top: 16vh;
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {
  .feedback-wrapper img {
    width: 120px;
    padding: 9%;
  }
  div#modal {
    width: max-content;
  }
}

#card-feedback {
  display: none;
  left: 0;
  z-index: 99999999999999;
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
  margin: auto;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
  animation-name: FadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

#like:hover,
#unlike:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  #contact-modal-close {
    display: block;
  }
}
</style>
