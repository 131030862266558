<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-app" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <p>
                  Um aplicativo de celular simples, com sistema de pagamento, e
                  liberado para ambas as plataformas Android e IoS, consome de
                  100 a 900 horas para ser desenvolvido. E tem o custo
                  aproximado entre R$ 8 mil e R$ 88 mil.
                </p>
                <p>
                  Aplicativos mais complexos podem custar entre R$ 150 mil e R$
                  300 mil. Lembrando que esses valores são os gastos apenas com
                  o desenvolvimento da fase inicial do projeto.
                </p>
                <p>
                  Um projeto pequeno demora de 3 a 6 meses e grandes projetos de
                  8 meses a 1 ano e meio. Mesmo em um projeto de 150 horas de
                  trabalho, não é possível resolver em um mês, pois as etapas
                  exigem interação de pessoas distintas e isso impacta na
                  sequência contínua do projeto, além das fases de testes e
                  correções.
                </p>
                <p>
                  Depois de pronto, o aplicativo ainda terá outros custos:
                  manutenção e evolução iniciando em R$ 2 mil reais por mês. O
                  custo de hospedagem em um servidor inicia em R$ 200 mensais.
                  No caso de aplicativos complexos, ou com grande volume de uso
                  e que vão exigir servidores mais potentes, o custo total pode
                  variar entre R$ 1 mil a R$ 12 mil por mês.
                </p>
              </div>

              <div class="paragraph">
                <h2 class="title-outline" style="font-size: 24px">
                  Fatores que pesam no custo do aplicativo
                </h2>
                <p>
                  Existem inúmeros aspectos que podem aumentar a complexidade e
                  o preço de um aplicativo. Então, na hora de montar o seu,
                  considere se o seu aplicativo:
                </p>
                <p></p>
                <ul class="paragraph-ul">
                  <li>Estará disponível para Android e iOS</li>
                  <li>Irá possuir interface padrão ou customizada</li>
                  <li>Ter uma área para login</li>
                  <li>Ter carrinho para compras</li>
                  <li>Oferecer interação com o usuário</li>
                  <li>
                    Será oferecido apenas em português ou também em outros
                    idiomas.
                  </li>
                </ul>
                <p>
                  Há ainda outros aspectos a avaliar, mas a partir dessas
                  perguntas um bom ponto de partida para estimar quanto custa
                  criar um aplicativo.
                </p>
                <p>
                  Se o projeto de APP não der certo, dificilmente encontrará
                  outro fornecedor disponível a continuar o projeto, pois é
                  muito custoso e às vezes inviável trabalhar sobre algo feito
                  da forma errada, ou com tecnologias que não são as escolhidas
                  pelo desenvolvedor. É fundamental sentir confiança total na
                  equipe que estará à frente do projeto antes da contratação.
                </p>
                <p>
                  Ao contratar uma empresa para desenvolver seu aplicativo,
                  certifique-se de ter alguém destacado em seu colaboradores,
                  para estar trabalhando em conjunto com a empresa de
                  desenvolvimento. Caso você esteja começando sua empresa
                  provavelmente você cumprirá esse papel, isso te custará algum
                  tempo, e ter uma empresa com um time completo e gestores de
                  confiança com experiência no mercado te deixará mais
                  confortável.
                </p>
                <p>
                  O custo de um aplicativo é relativamente alto, comparado com
                  um sistema web, e talvez começar com o que chamamos de
                  <a @click="$router.push({ name: 'Duvida08' })">
                    MVP - Minimum Viable Product</a
                  >, seja algo mais viável para validar sua ideia primeiro. Os
                  aplicativos móveis precisam ser desenvolvidos por
                  profissionais de software experientes e designers que projetam
                  a usabilidade e o layout do aplicativo.
                </p>
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->

            <div class="col-md-6">
              <div class="container">
                <div class="paragraph">
                  <h2 class="title-outline" style="font-size: 24px">
                    Por que criar um app é complexo?
                  </h2>

                  <p>
                    Vamos entender melhor como é o desenvolvimento de
                    aplicativos. Veja quais profissionais são necessários para
                    desenvolver e executar um projeto de aplicativo para
                    celular:
                  </p>
                  <h3 style="font-size: 20px">Designer</h3>
                  <p>
                    Responsável por elaborar o desenho das interfaces do app,
                    primando pela usabilidade, elemento fundamental para o
                    sucesso do aplicativo. Algumas empresas nomeiam esse cargo
                    como Front End Designer ou Front End Engineer.
                  </p>
                  <h3 style="font-size: 20px">Analista de Sistemas</h3>
                  <p>
                    É responsável por compreender a necessidade de negócio do
                    cliente e especificar por escrito o que precisa ser feito no
                    projeto. É um profissional com bagagem em desenvolvimento de
                    software e, em alguns casos, também ajuda na programação.
                  </p>
                  <h3 style="font-size: 20px">Arquiteto de Software</h3>
                  <p>
                    Analisa as necessidades do projeto e define a arquitetura
                    técnica que melhor se encaixa no projeto. É comum sua
                    participação na programação do aplicativo, sendo responsável
                    pelas partes mais complexas do projeto.
                  </p>
                  <h3 style="font-size: 20px">Desenvolvedor / Programador</h3>
                  <p>
                    Transforma as especificações de negócio do aplicativo em
                    código, seguindo as diretrizes técnicas do arquiteto e
                    análise funcional do analista de sistemas. O código fonte
                    faz a conexão com banco de dados e a camada visual, para
                    leitura, gravação e exposição das informações. Essa parte
                    representa em torno de 50% do esforço total de um projeto de
                    desenvolvimento de aplicativo para celular.
                  </p>
                  <h3 style="font-size: 20px">
                    Analista de Banco de Dados (DBA)
                  </h3>
                  <p>
                    Em projetos menores o próprio analista de sistema, arquiteto
                    de software ou programador, pode executar esse papel. Em
                    projetos maiores, a figura de um DBA (Database Analyst) é
                    fundamental para tratar adequadamente grandes volumes de
                    dados. Ele é responsável por definir a arquitetura do banco
                    de dados e apoiar na criação dos comandos para tratamento
                    das rotinas mais complexas de manipulação de dados.
                  </p>
                  <h3 style="font-size: 20px">Analista de Testes</h3>
                  <p>
                    Faz a validação do aplicativo, ou seja, se a etapa de
                    codificação cumpriu o que foi solicitado na especificação do
                    Analista de Sistemas e se não há erros (bugs) no app.
                    Dependendo da configuração da equipe, o próprio analista de
                    sistemas pode executar a etapa de validação.
                  </p>
                  <h3 style="font-size: 20px">
                    Gerente de Projetos ou Coordenador
                  </h3>
                  <p>
                    Profissional que cria e acompanha o cronograma do projeto,
                    distribuindo as tarefas para os profissionais.
                  </p>
                  <p>
                    Quanto menor o projeto, mais fácil é reunir as competências
                    em poucas pessoas, tornando alguns profissionais
                    multitarefas, por isso muitas pessoas tendem a contratar
                    freelancers. No entanto, pelo menos um designer e um
                    desenvolvedor experiente existem em todos os projetos, se
                    você não tem experiência com tecnologia, contar com uma
                    empresa terceirizando essas responsabilidades é a melhor
                    decisão.
                  </p>
                  <p>
                    Venha desenvolver seu app, entrando em contato através do
                    email
                    <a href="mailto:comercial@shinier.com.br"
                      >comercial@shinier.com.br</a
                    >
                    , ou por WhatsApp
                    <a @click="send()">+5516981354817 (Marcius)</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import send from "@/mixins/sendMessage";
import Duvidas from "@/components/Duvidas";

export default {
  name: "Duvida01",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR",
    },
    title: "Quanto custa para desenvolver um aplicativo?",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary",
      },
      {
        name: "twitter:title",
        content: "Quanto custa para desenvolver um aplicativo? | Shinier",
      },
      {
        name: "description",
        content:
          "Um aplicativo de celular simples, com sistema de pagamento, e liberado para ambas as plataformas Android e IoS, consome de 100 a 900 horas para ser desenvolvido. E tem o custo aproximado entre R$ 8 mil e R$ 88 mil.",
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Quanto custa para desenvolver um aplicativo? | Shinier",
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/quanto-custa-desenvolver-um-aplicativo",
      },
      {
        property: "og:description",
        content:
          "Um aplicativo de celular simples, com sistema de pagamento, e liberado para ambas as plataformas Android e IoS, consome de 100 a 900 horas para ser desenvolvido. E tem o custo aproximado entre R$ 8 mil e R$ 88 mil.",
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/app-example.a32ddbc8.png",
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/app-example.a32ddbc8.png",
      },
      {
        name: "twitter:description",
        content:
          "Um aplicativo de celular simples, com sistema de pagamento, e liberado para ambas as plataformas Android e IoS, consome de 100 a 900 horas para ser desenvolvido. E tem o custo aproximado entre R$ 8 mil e R$ 88 mil.",
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" },
    ],
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback
  },
  mixins: [showQuestions, scrollToTop, send],
};
</script>
<style>
@import url("./duvidas.css");
</style>
