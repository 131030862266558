<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <PageHeader imgBack="img-banner-site" />
        <section class="list list-new-destaques segmentos">
          <div class="container" style="width: 100%">
            <!-- começa o primeiro parágrafo com a desc
             e após os conteúdos-->
            <div class="col-md-6">
              <h1 class="title2" style="text-align: justify">
                {{ title }}
              </h1>

              <div class="paragraph">
                <p>{{ desc }}</p>
                <p>
                  Sites responsivos são sites que são projetados para caber em
                  seu smartphone ou tablet. Se você visualizar seu site em seu
                  celular ou tablet (e não mais em seu computador), as
                  informações permanecem as mesmas, mas reposicionadas para
                  proporcionar aos usuários uma experiência de navegação mais
                  confortável. Já um web app é um site que se comporta como um
                  aplicativo, que é acessado de um navegador e se comporta de
                  forma responsiva como qualquer outro site. Você também pode
                  criar um ícone de site para acesso direto à tela inicial do
                  seu smartphone, mas, por exemplo, os aplicativos da web têm
                  algumas restrições em relação aos aplicativos nativos. O
                  aplicativo web por sua vez não pode usar todos os recursos do
                  smartphone, o que impossibilita algumas ações. Ele não ocupa o
                  espaço de armazenamento do dispositivo (pois não precisa ser
                  baixado), mas só funciona se você tiver acesso à internet.
                </p>

                <p>
                  O preço médio para a criação de um site pode ficar entre R$
                  2.500,00 a R$ 15.000,00 para um site com 1 (1 página) a 16
                  páginas. Vale ressaltar que a precificação de um site
                  institucional é diferente de uma loja virtual. Existem
                  diferenças nos tipos de sites, mas é importante levar isso em
                  consideração. Cada tipo atende a diferentes requisitos,
                  dependendo de cada necessidade, e indica qual a aplicação mais
                  adequada para sua empresa. Já os custos envolvendo manutenção
                  de um site podem variar entre R$ 500 e R$ 5.000 no caso de
                  sites de pequenas ou grandes empresas.
                </p>
                <p>
                  Ao criar um site, seu negócio gera mais possibilidades de
                  interação, facilitando a aproximação e o compreendimento do
                  seu público alvo. Melhorando a comunicação, você tem acesso a
                  mais feedbacks, e assim podendo adequar melhor os seus
                  produtos às necessidades dos seus clientes.
                </p>
                <p>
                  Venha desenvolver seu app, entrando em contato através do
                  email
                  <a href="mailto:comercial@shinier.com.br"
                    >comercial@shinier.com.br</a
                  >
                  , ou por WhatsApp
                  <a @click="send()">+5516981354817 (Marcius)</a>
                </p>
              </div>
            </div>
            <!-- fim col-md-6 esquerda -->

            <div class="col-md-6">
              <div class="container">
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/coding.png"
                    alt="Pessoa desenvolvendo software"
                  />
                </figure>
                <figure class="figure figure-question">
                  <img
                    class="img-responsive img-loaded"
                    src="../../assets/images/website-development.png"
                    alt="Notebook com wireframe na tela"
                  />
                </figure>
              </div>
            </div>
            <!-- fim col-md-6 direita -->
          </div>
        </section>
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção feedback -->
        <section>
          <Duvidas />
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import PageHeader from "@/components/Banner/PageHeader";
import scrollToTop from "@/mixins/scrollToTop";
import showQuestions from "@/mixins/showQuestions";
import send from "@/mixins/sendMessage";
import Duvidas from "@/components/Duvidas";

export default {
  name: "Duvida09",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Quanto custa para desenvolver um site?",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "Quanto custa para desenvolver um site? | Shinier"
      },

      {
        name: "description",
        content:
          "Um site simples, como uma página estática e responsivo consome em média 30 horas, enquanto algo mais complexo vai variar de 160 horas ou mais. Assim fica claro que o levantamento detalhado dos requisitos é necessário antes de qualquer orçamento."
      },

      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Quanto custa para desenvolver um site? | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/duvidas/quanto-custa-para-desenvolver-um-site"
      },
      {
        property: "og:description",
        content:
          "Um site simples, como uma página estática e responsivo consome em média 30 horas, enquanto algo mais complexo vai variar de 160 horas ou mais. Assim fica claro que o levantamento detalhado dos requisitos é necessário antes de qualquer orçamento."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/website-development.d29c63bf.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/website-development.d29c63bf.png"
      },
      {
        name: "twitter:description",
        content:
          "Um site simples, como uma página estática e responsivo consome em média 30 horas, enquanto algo mais complexo vai variar de 160 horas ou mais. Assim fica claro que o levantamento detalhado dos requisitos é necessário antes de qualquer orçamento."
      },
      { property: "og:site_name", content: "Shinier | Principais Dúvidas" }
    ]
  },
  components: {
    PageHeader,
    Duvidas,
    GetFeedback
  },
  mixins: [showQuestions, scrollToTop, send]
};
</script>
<style>
@import url("./duvidas.css");
</style>
